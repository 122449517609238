@media print {
  .no-print {
    display: none !important;
  }
}

body {
  margin: 0;
}

.rotate {
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(360deg);
  }

  100% {
    transform: rotate(0deg);
  }
}